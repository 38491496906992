<template>
  <div>
    <div class="policy-details">
      <div class="app-contain">
        <div class="contain-content w">
          <div class="details-top">
            <h3 class="details-title" :title="xq.title">
              {{ xq.title }}
            </h3>

            <div class="details-per">
              <p class="details-per-one">
                <span>政策地区：</span>
                <span class="details-info">
                  {{ xq.provincename }}{{ xq.cityname }}{{ xq.areaname }}</span
                >
              </p>
              <p class="details-per-one">
                <span>政策级别：</span>
                <span class="details-info">{{ xq.level }}</span>
              </p>
            </div>
            <div class="details-per">
              <p class="details-per-one details-per-ones">
                <span>发布时间：</span>
                <span class="details-info">{{
                  xq.created_at | formatDate
                }}</span>
              </p>
              <p class="details-per-one">
                <span>主管部门：</span>
                <span class="details-info">{{ xq.dept }}</span>
              </p>
            </div>
          </div>
          <div class="details-p">
            <div class="support">
              <h3 class="support-title">政策详情</h3>
              <div class="support-info" v-html="xq.details"></div>
            </div>

            <!-- <div class="details-p-info" v-show="xq.addfiles">
              <div class="support support-title-content">
                <h3 class="support-title">附件</h3>
              </div>
              <div class="accessory_b">
                <i class="el-icon-download"></i>
                <div class="accessory-name">
                  附件下载：
               
                   <a :href="'http://hout.kehui.cloud'+xq.addfiles" target="_blank" rel="noopener">{{xq.addfiles}}</a>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!---->
    </div>
  </div>
</template>

<script>
import { PolicyXq } from "../../../common/js/api";
import { formatDate } from "../../../common/js/times"; //时间
export default {
  name: "PolicyNei",
  data() {
    return {
      id: "",
      xq: {},
    };
  },
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getxq();
  },
  methods: {
    // 政府详情
    getxq() {
      PolicyXq({ id: this.id })
        .then((res) => {
          console.log(res);
          this.xq = res.data;
          this.xq.level = this.xq.leveltype.name;
          this.xq.details = this.xq.details.replace(/<\/?(img)[^>]*>/gi, "");
        })
        .catch(() => {});
    },
    
  },
};
</script>

<style scoped lang="less">
.policy-details {
  min-height: 600px;
  background-color: #f8f9fa;
  margin-top: 135px;
  .contain-content {
    padding: 28px 0 75px;
    .details-top {
      padding: 29px 50px 33px 25px;
      border-radius: 4px;
      background-color: #fff;
      overflow: hidden;
      .details-title {
        font-size: 28px;
        width: 984px;
        color: #101010;
        margin-bottom: 30px;
            line-height: 1.4;
      }
      .details-per {
        margin-top: 16px;
        .details-per-one {
          width: 33%;
          display: inline-block;
          font-size: 16px;
          color: #101010;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .details-p {
      margin-top: 52px;
      padding: 29px 18px 20px 25px;
      border-radius: 4px;
      background-color: #fff;
      .support {
        // margin-bottom: 60px;
        .support-title {
          font-size: 18px;
          font-weight: 700;
          color: #101010;
        }
        .support-info {
          min-height: 419px;
          font-size: 16px;
          line-height: 24px;
          margin-top: 20px;
          color: #101010;
          overflow: hidden;
          text-indent: 2em;
        }
      }
      .accessory_b {
        margin-top: 10px;
        color: #3892eb;
        height: 200px;
        font-size: 16px;
        .accessory-name {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
	}
	.policy-details{
		margin-top: 15rem;
	}
	.policy-details .contain-content .details-top .details-title{
		font-size: 2.4rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		width: 90%;
	}
	.policy-details .contain-content .details-top .details-per .details-per-one{
		font-size: 1.4rem;
		width: 50%;
	}
	.policy-details .contain-content .details-p .support .support-title{
		font-size: 2.2rem;
	}
	.policy-details .contain-content .details-p .support .support-info{
		font-size: 1.5rem;
		line-height: 2.2rem;
	}
}
</style>
